import { createWebHistory, createRouter } from "vue-router";
import { defineAsyncComponent } from "vue";

const MainView = defineAsyncComponent(() => import("@/views/main.vue"));

const Login = defineAsyncComponent(() => import("@/views/Login/login.vue"));

const ForgotPassword = defineAsyncComponent(() =>
  import("@/views/Login/forgot-password.vue")
);
const Dashboard = defineAsyncComponent(() =>
  import("@/views/Dashboard/dashboard.vue")
);

const Users = defineAsyncComponent(() => import("@/views/User/users.vue"));

const UsersDetail = defineAsyncComponent(() =>
  import("@/views/User/user-details.vue")
);

const UserProfile = defineAsyncComponent(() =>
  import("@/views/User/profile.vue")
);

const Roles = defineAsyncComponent(() => import("@/views/Roles/roles.vue"));

const AddRole = defineAsyncComponent(() =>
  import("@/views/Roles/add-role.vue")
);

const Admin = defineAsyncComponent(() => import("@/views/Admin/admin.vue"));

const AddAdmin = defineAsyncComponent(() =>
  import("@/views/Admin/add-admin.vue")
);

const Platform = defineAsyncComponent(() =>
  import("@/views/Platform/platform.vue")
);

const AddPlatform = defineAsyncComponent(() =>
  import("@/views/Platform/add-platform.vue")
);

const Interest = defineAsyncComponent(() =>
  import("@/views/Interest/interest.vue")
);

const AddInterest = defineAsyncComponent(() =>
  import("@/views/Interest/add-interest.vue")
);

const Event = defineAsyncComponent(() => import("@/views/Event/events.vue"));

const EventDetail = defineAsyncComponent(() =>
  import("@/views/Event/eventDetail.vue")
);

const EventUpdate = defineAsyncComponent(() =>
  import("@/views/Event/event-update.vue")
);

const EventUpdateDetail = defineAsyncComponent(() =>
  import("@/views/Event/event-update-detail.vue")
);

const ChangePassword = defineAsyncComponent(() =>
  import("@/views/User/change-password.vue")
);

const StaticPage = defineAsyncComponent(() =>
  import("@/views/Static Page/static-page.vue")
);

const AddStaticPage = defineAsyncComponent(() =>
  import("@/views/Static Page/add-static-page.vue")
);

const Reason = defineAsyncComponent(() => import("@/views/Reason/reason.vue"));

const AddReportReason = defineAsyncComponent(() =>
  import("@/views/Reason/add-report-reason.vue")
);

const Report = defineAsyncComponent(() => import("@/views/Reason/report.vue"));

const Setting = defineAsyncComponent(() =>
  import("@/views/Setting/setting.vue")
);

const EventSharedDetail = defineAsyncComponent(() =>
  import("@/views/Shared/eventDetail.vue")
);

const ProfileSharedDetail = defineAsyncComponent(() =>
  import("@/views/Shared/profileDetail.vue")
);

const ReportExcel = defineAsyncComponent(() =>
  import("@/views/Report/report.vue")
);

const Notification = defineAsyncComponent(() =>
  import("@/views/Notification/notifications.vue")
);

const NotificationUser = defineAsyncComponent(() =>
  import("@/views/Notification/notification-user.vue")
);

const AddNotification = defineAsyncComponent(() =>
  import("@/views/Notification/add-notification.vue")
);

const Subscription = defineAsyncComponent(() =>
  import("@/views/Subscription/subscription.vue")
);

const AddSubscription = defineAsyncComponent(() =>
  import("@/views/Subscription/add-subscription.vue")
);

const Country = defineAsyncComponent(() =>
  import("@/views/Country/country.vue")
);

const AddCountry = defineAsyncComponent(() =>
  import("@/views/Country/add-country.vue")
);

const Perk = defineAsyncComponent(() =>
  import("@/views/Perk/perks.vue")
);

const AddPerk = defineAsyncComponent(() =>
  import("@/views/Perk/add-perk.vue")
);

const PerkLog = defineAsyncComponent(() =>
  import("@/views/Perk/perk-log.vue")
);

const routes = [
  {
    path: "/",
    redirect: Login,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPassword,
  },

  {
    path: "/event-detail/:uuid",
    name: "EventSharedDetail",
    component: EventSharedDetail,
  },

  {
    path: "/profile-detail/:id",
    name: "ProfileSharedDetail",
    component: ProfileSharedDetail,
  },
  {
    path: "",
    name: "Main",
    component: MainView,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "/profile",
        name: "User Profile",
        component: UserProfile,
      },
      {
        path: "/dashboard",
        name: "DashboardPage",
        component: Dashboard,
      },
      {
        path: "/users",
        name: "User",
        component: Users,
      },
      {
        path: "/user-details",
        name: "User Details",
        component: UsersDetail,
      },

      {
        path: "/roles",
        name: "Roles",
        component: Roles,
      },

      {
        path: "/admins",
        name: "Admin",
        component: Admin,
      },
      {
        path: "/add-admin",
        name: "AddAdmin",
        component: AddAdmin,
      },
      {
        path: "/update-admin",
        name: "UpdateAdmin",
        component: AddAdmin,
      },
      {
        path: "/change-password",
        name: "ChangePassword",
        component: ChangePassword,
      },

      {
        path: "/platforms",
        name: "Platform",
        component: Platform,
      },
      {
        path: "/add-platform",
        name: "AddPlatform",
        component: AddPlatform,
      },
      {
        path: "/update-platform",
        name: "UpdatePlatform",
        component: AddPlatform,
      },

      {
        path: "/interests",
        name: "Interest",
        component: Interest,
      },
      {
        path: "/add-interest",
        name: "AddInterest",
        component: AddInterest,
      },
      {
        path: "/update-interest",
        name: "UpdateInterest",
        component: AddInterest,
      },
      {
        path: "/events",
        name: "Event",
        component: Event,
      },
      {
        path: "/event-details",
        name: "EventDetail",
        component: EventDetail,
      },
      {
        path: "/event-updates",
        name: "EventUpdate",
        component: EventUpdate,
      },

      {
        path: "/event-update-detail",
        name: "EventUpdateDetail",
        component: EventUpdateDetail,
      },

      {
        path: "/add-role",
        name: "AddRole",
        component: AddRole,
      },
      {
        path: "/update-role",
        name: "UpdateRole",
        component: AddRole,
      },

      {
        path: "/static_pages",
        name: "StaticPage",
        component: StaticPage,
      },
      {
        path: "/add-static-page",
        name: "AddStaticPage",
        component: AddStaticPage,
      },
      {
        path: "/update-static-page",
        name: "UpdateStaticPage",
        component: AddStaticPage,
      },
      {
        path: "/report_reasons",
        name: "ReportReason",
        component: Reason,
      },

      {
        path: "/add-report-reason",
        name: "AddReportReason",
        component: AddReportReason,
      },

      {
        path: "/update-report-reason",
        name: "UpdateReportReason",
        component: AddReportReason,
      },
      {
        path: "/report_bugs",
        name: "ReportBUgs",
        component: Report,
      },
      {
        path: "/reports",
        name: "ReportPage",
        component: ReportExcel,
      },
      {
        path: "/notifications",
        name: "Notification",
        component: Notification,
      },
      {
        path: "/notification-user",
        name: "NotificationUser",
        component: NotificationUser,
      },
      {
        path: "/add-notification",
        name: "AddNotification",
        component: AddNotification,
      },

      {
        path: "/setting",
        name: "Setting",
        component: Setting,
      },

      {
        path: "/subscriptions",
        name: "Subscription",
        component: Subscription,
      },
      {
        path: "/add-subscription",
        name: "AddSubscription",
        component: AddSubscription,
      },
      {
        path: "/update-subscription",
        name: "UpdateSubscription",
        component: AddSubscription,
      },

      {
        path: "/country",
        name: "Country",
        component: Country,
      },
      {
        path: "/add-country",
        name: "AddCountry",
        component: AddCountry,
      },
      {
        path: "/update-country",
        name: "UpdateCountry",
        component: AddCountry,
      },

      {
        path: "/perks",
        name: "Perk",
        component: Perk,
      },
      {
        path: "/perk-logs",
        name: "PerkLog",
        component: PerkLog,
      },
      {
        path: "/add-perk",
        name: "AddPerk",
        component: AddPerk,
      },
      {
        path: "/update-perk",
        name: "UpdatePerk",
        component: AddPerk,
      },
    ],
  },
];
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (localStorage.getItem("access_token") == null) {
      next({
        path: "/login",
      });
    } else {
      next();
    }
  } else {
    if (to.path === "/" || to.path === "/login") {
      if (localStorage.getItem("access_token") != null) {
        next({
          path: "/profile",
        });
      } else {
        next();
      }
    } else {
      next();
    }
  }
});

export default router;
